import "../assets/css/home.css";
import facebook from '../assets/images/f.png'
import instagram from '../assets/images/Instagram.png'
const Footer = () => {
    return (
        <ul className="social">
            <li><a target='_blank' href="https://www.facebook.com/profile.php?id=100067441555061"><img style={{"width":"50px"}} src={facebook} /></a></li>
            <li><a href="#"><img style={{"width":"50px"}} src={instagram} /></a></li>
            <li className="footer-rights"> © 2025 El Dabi Tex All rights reserved. </li>
        </ul>
    );
}

export default Footer;