import { useEffect, useRef, useState } from "react";
import '../assets/css/navbar.css'
import '../assets/css/shop.css'
import Navbar from "../components/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Footer from "../components/Footer";
import logo from '../assets/images/logo-10.png'
import fire from '../assets/images/fire.png'
import solar from '../assets/images/solar.png'
import sun from '../assets/images/sun.png'
import virus from '../assets/images/no-virus.png'
import q from '../assets/images/q.jpg'
import w from '../assets/images/w.jpg'
import e from '../assets/images/e.jpg'
import category4 from '../assets/images/category4.jpg'
import category5 from '../assets/images/category5.jpg'
import category6 from '../assets/images/category6.jpg'
import category7 from '../assets/images/category7.png'
import category8 from '../assets/images/category8.jpg'
import category9 from '../assets/images/category9.jpg'
import category10 from '../assets/images/category10.jpg'
import { FaArrowUp } from "react-icons/fa";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { FaTextWidth } from "react-icons/fa6";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangDropDown from "../components/LangDropDown";

const Shop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const showCaseRef = useRef();
    const navRef = useRef();
    const navigate = useNavigate();
    const buttonRef = useRef();
    const { t, i18n } = useTranslation();
    const categories = [
        { srcimg: q, description: '  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.', name: 'Shoaayrat', gsm: '160', width: '160 cm ' },
        { srcimg: w, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Zebda', gsm: '155', width: '155 cm ' },
        { srcimg: e, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Fiber', gsm: '160', width: '160 cm ' },
        { srcimg: category4, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Dafya', gsm: '180', width: '160 cm ' },
        { srcimg: category5, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Heidi', gsm: '260', width: '180 cm ' },
        { srcimg: category6, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Lameh', gsm: '180', width: '160 cm ' },
        { srcimg: category7, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Single Lycra', gsm: '190', width: '165 cm ' },
        { srcimg: category8, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Octavia', gsm: '240', width: '160 cm ' },
        { srcimg: category9, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Farsh', gsm: '-', width: '- ' },
        { srcimg: category10, description: "description:'  a blend of polyester and polypropylene, are renowned for their exceptional durability and resilience in fabric manufacturing. These fibers offer fabrics that are highly resistantto moisture and chemicals, making them ideal for creating durable outdoor and industrial textiles. Shoayrat fibers contribute to the production of versatile fabrics known for theirstrength and performance in challenging environments.", name: 'Polar', gsm: '320', width: '200 cm ' },

    ]
    // setupIntersectionObserver();
    const showNavbar = () => {
        navRef.current.classList.toggle(
            "active"
        );
        showCaseRef.current.classList.toggle(
            "active"
        );
        buttonRef.current.classList.toggle(
            "active"
        );
    };
    const scrollTodown = () => {
        window.scrollTo({
            top: 920,
            behavior: 'smooth' // Smooth scrolling animation
        });
    };
    useEffect(() => {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {

                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    // entry.target.classList.remove('show');
                }
            });
        });

        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));
        const handleScroll = () => {
            if (window.pageYOffset > 1000) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };


    }, [])
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling animation
        });
    };
    return (
        <div className="shop-container" >
            <div className="showcase" ref={showCaseRef}>
                <header>
                    <Link to={'/'}>

                        <img style={{ "width": "5%" }} src={logo} />
                    </Link>
                    <div className="right-part-header">
                        <div className="language-holder">
                            <LangDropDown />
                        </div>
                        <div onClick={showNavbar} ref={buttonRef} className="toggle"></div>
                    </div>
                </header>
                <div className="shop-content">
                    <h2>
                        {t("shop1")}

                    </h2>
                    <p>
                        {t("shop2")}

                    </p>
                    <svg
                        style={{ marginTop: '20px' }}
                        onClick={() => scrollTodown()}
                        viewBox="0 0 24 24"
                        width="40"  // Adjust width as needed
                        height="40" // Adjust height as needed
                        className="mt-16 animate-bounce_1.5s_ease-in-out_infinite text-accent animate-bounce" // Add classes
                        astro-icon="iconoir:mouse-scroll-wheel" // Add custom attribute
                        aria-label="Scroll Down Icon" // Add aria-label for accessibility
                    // role="img" // Add role="img" to indicate that this represents an image
                    >
                        <path
                            fill="currentColor"
                            strokeWidth="1.5"
                            d="m12 5 .53-.53a.75.75 0 0 0-1.06 0L12 5zm0 8-.53.53a.75.75 0 0 0 1.06 0L12 13zM9.47 6.47a.75.75 0 0 0 1.06 1.06L9.47 6.47zm4 1.06a.75.75 0 1 0 1.06-1.06l-1.06 1.06zm-2.94 2.94a.75.75 0 1 0-1.06 1.06l1.06-1.06zm4 1.06a.75.75 0 1 0-1.06-1.06l1.06 1.06zM3.25 10v4h1.5v-4h-1.5zm17.5 4v-4h-1.5v4h1.5zm-9.5-9v8h1.5V5h-1.5zm.22-.53-2 2 1.06 1.06 2-2-1.06-1.06zm0 1.06 2 2 1.06-1.06-2-2-1.06 1.06zm1.06 6.94-2-2-1.06 1.06 2 2 1.06-1.06zm0 1.06 2-2-1.06-1.06-2 2 1.06 1.06zM20.75 10A8.75 8.75 0 0 0 12 1.25v1.5A7.25 7.25 0 0 1 19.25 10h1.5zM12 22.75A8.75 8.75 0 0 0 20.75 14h-1.5A7.25 7.25 0 0 1 12 21.25v1.5zM3.25 14A8.75 8.75 0 0 0 12 22.75v-1.5A7.25 7.25 0 0 1 4.75 14h-1.5zm1.5-4A7.25 7.25 0 0 1 12 2.75v-1.5A8.75 8.75 0 0 0 3.25 10h1.5z"
                        // d="M12 18.25L5.51 11.76a1.5 1.5 0 0 1 2.12-2.12L12 14.01l4.37-4.37a1.5 1.5 0 0 1 2.12 2.12L12 18.25z"
                        ></path>
                    </svg>
                </div>
                <div className="fabrics-type-container">

                    <div className="card hidden">
                        <input type="checkbox" id="card1" className="more" aria-hidden="true" />
                        <div className="card-content">
                            <div className="front type-1" >
                                <div className="inner">
                                    <h2>{t("shop3")}</h2>
                                    <label htmlFor="card1" className="button" aria-hidden="true">
                                        {t("shop4")}
                                    </label>
                                </div>
                            </div>
                            <div className="back ">
                                <div className="inner">
                                    <div className="info">
                                        <span> <img src={fire} />{t("shop5")}</span>

                                    </div>
                                    <div className="info">
                                        <span><img src={sun} />{t("shop6")} </span>

                                    </div>
                                    <div className="info">
                                        <span><img src={solar} />{t("shop7")} </span>

                                    </div>
                                    <div className="info">
                                        <span><img src={virus} /> {t("shop8")}</span>

                                    </div>
                                    <div className="description">
                                        <p> {t("shop9")}</p>
                                    </div>
                                    <label htmlFor="card1" className="button return" aria-hidden="true">
                                        <FontAwesomeIcon icon={faArrowLeft} color="black" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card hidden">
                        <input type="checkbox" id="card2" className="more" aria-hidden="true" />
                        <div className="card-content">
                            <div className="front type-2" >
                                <div className="inner">
                                    <h2>{t("shop10")}</h2>
                                    <label htmlFor="card2" className="button" aria-hidden="true">
                                        {t("shop4")}
                                    </label>
                                </div>
                            </div>
                            <div className="back">
                                <div className="inner">
                                    <div className="info">
                                        <span> <img src={fire} /> {t("shop5")}</span>

                                    </div>
                                    <div className="info">
                                        <span><img src={sun} /> {t("shop6")} </span>

                                    </div>
                                    <div className="info">
                                        <span><img src={solar} />{t("shop7")} </span>

                                    </div>
                                    <div className="info">
                                        <span><img src={virus} /> {t("shop8")}</span>

                                    </div>
                                    <div className="description">
                                        <p>{t("shop11")} </p>

                                    </div>
                                    <label htmlFor="card2" className="button return" aria-hidden="true">
                                        <FontAwesomeIcon icon={faArrowLeft} color="black" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card hidden">
                        <input type="checkbox" id="card3" className="more" aria-hidden="true" />
                        <div className="card-content">
                            <div className="front type-3" >
                                <div className="inner">
                                    <h2>{t("shop12")}</h2>
                                    <label htmlFor="card3" className="button" aria-hidden="true">
                                        {t("shop4")}
                                    </label>
                                </div>
                            </div>
                            <div className="back">
                                <div className="inner">
                                    <div className="info">
                                        <span> <img src={fire} /> {t("shop5")}</span>

                                    </div>
                                    <div className="info">
                                        <span><img src={sun} /> {t("shop6")}</span>

                                    </div>
                                    <div className="info">
                                        <span><img src={solar} /> {t("shop7")}</span>

                                    </div>
                                    <div className="info">
                                        <span><img src={virus} />{t("shop8")} </span>

                                    </div>
                                    <div className="description">
                                        <p>{t("shop13")}</p>
                                    </div>

                                    <label htmlFor="card3" className="button return" aria-hidden="true">
                                        <FontAwesomeIcon icon={faArrowLeft} color="black" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="types-bg">
                <h1 className="cat-text" style={{"text-align":"center",'color':"white"}}> {t("shop20")} <span> {t("shop17")}</span></h1>
                <div style={{"text-align":"center"}} className="vertical-border"></div>
                    <div className="types">
                        <div className="type-box">
                            <h4>
                                Micro Fiber
                            </h4>
                            <p>
                                &#10687;  &nbsp;Brushed Micro Fiber Printed
                            </p>
                            <p>
                                &#10687;   &nbsp;Brushed Micro Fiber Printed Double Face
                            </p>
                            <p>
                                &#10687;  &nbsp;Brushed Micro Fiber Dyed
                            </p>
                            <p>
                                &#10687;  &nbsp;Brushed Micro Fiber Dyed Double Face
                            </p>
                            <h4>
                                Spun
                            </h4>
                            <p>
                                &#10687;  &nbsp;Spun Printed
                            </p>
                            <p>
                                &#10687;   &nbsp; Spun Dyed
                            </p>
                        </div>

                        <div className="type-box">
                            <h4>
                                Polar

                            </h4>
                            <p>
                                &#10687;  &nbsp;Polar One Face Dyed

                            </p>
                            <p>
                                &#10687;  &nbsp;Polar Two Face Dyed

                            </p>
                            <p>
                                &#10687;  &nbsp;Polar One Face Printed

                            </p>
                            <p>
                                &#10687;  &nbsp;Polar Two Face Printed

                            </p>
                            <p>
                                &#10687;  &nbsp;Polar One Face Jakar

                            </p>
                            <p>
                                &#10687;  &nbsp;Polar Double Face Jakar

                            </p>
                            <h4>
                                Spandex
                            </h4>
                            <p>
                                &#10687;  &nbsp;Viscose 100% Spandex Fabric


                            </p>
                        </div>

                        <div className="type-box">

                            <h4>
                                Cotton

                            </h4>
                            <p>
                                &#10687;  &nbsp;Single Jersey Cotton Fabric 36/1 With Spandex

                            </p>
                            <p>
                                &#10687;  &nbsp;Single Jersey Cotton Fabric 30/1 With Spandex

                            </p>
                            <p>
                                &#10687;  &nbsp;Single Jersey Cotton Fabric 40/1 With Spandex

                            </p>
                            <p>
                                &#10687;  &nbsp;Single Jersey Cotton Fabric 36/1 Without Spandex

                            </p>
                            <p>
                                &#10687;  &nbsp;Single Jersey Cotton Fabric 30/1 Without Spandex
                            </p>
                            <h4>
                                Swimsuits Waterproof

                            </h4>
                            <p>
                                &#10687;  &nbsp;Warp Knitting (100% Polyester - Spandex)

                            </p>
                            <p>
                                &#10687;  &nbsp;Micro Fiber (100% Polyester - Spandex)

                            </p>
                            <p>
                                &#10687;  &nbsp;Nylon (100% Polyester - Spandex)

                            </p>
                            <p>
                                &#10687;  &nbsp;Cupro

                            </p>
                        </div>
                    </div>
                    <div className="types">
                        <div className="type-box">
                            <h4>
                                Velvet

                            </h4>
                            <p>
                                &#10687;  &nbsp;Super Soft Velvet One Face

                            </p>
                            <p>
                                &#10687;   &nbsp;Super Soft Velvet Two Face

                            </p>
                            <p>
                                &#10687;  &nbsp;Super Soft Velvet Printed One Face

                            </p>
                            <p>
                                &#10687;  &nbsp;Super Soft Velvet Printed Two Face

                            </p>
                            <p>
                                &#10687;  &nbsp;Super Soft Velvet Pindex Fabric

                            </p>
                            <p>
                                &#10687;   &nbsp;Super Soft Jacquard Fabric Micro-elastic

                            </p>
                            <p>
                                &#10687;   &nbsp;Korean Fabric

                            </p>
                            <p>
                                &#10687;   &nbsp;Cotton Velvet Fabric

                            </p>
                            <p>
                                &#10687;   &nbsp;Polyester Velvet for Printed Carpet

                            </p>
                        </div>

                        <div className="type-box">
                            <h4>
                                Sport


                            </h4>
                            <p>
                                &#10687;  &nbsp;Bride Eye Mesh Quick Dry Fabric


                            </p>
                            <p>
                                &#10687;  &nbsp;Polyester Plaid Stretch Sport


                            </p>
                            <p>
                                &#10687;  &nbsp;Spandex Polyester Interlock Knit


                            </p>
                            <p>
                                &#10687;  &nbsp;Waffle Knit


                            </p>
                            <h4>
                                Zebda
                            </h4>
                            <p>
                                &#10687;  &nbsp;100% FDY Polyester Fabric 100% Spandex Dyed


                            </p>
                            <p>
                                &#10687;  &nbsp;100% FDY Polyester Fabric 100% Spandex Printed


                            </p>
                        </div>

                        <div className="type-box">
                            <h4>
                                Melton


                            </h4>
                            <p>
                                &#10687;  &nbsp;Brushed Melton Fleece Cotton


                            </p>
                            <p>
                                &#10687;  &nbsp;Brushed Melton Fleece Polyester


                            </p>
                            <p>
                                &#10687;  &nbsp;Brushed Melton Fleece Slub


                            </p>
                            <p>
                                &#10687;  &nbsp;Summer Melton Fleece
                            </p>
                            <p>
                                &#10687;  &nbsp;Summer Melton Fabric
                            </p>
                            <h4>
                                Brushed Fabric

                            </h4>
                            <p>
                                &#10687;  &nbsp;Fur Fabric

                            </p>
                            <p>
                                &#10687;  &nbsp;Brash Fabric

                            </p>
                        </div>
                    </div>

                </div>


                <div className="category-section-container">
                   
                    <div className="category-container">
                        {
                            categories.map((el, idx) => (
                                <div className="category " key={idx} style={{ backgroundImage: `url(${el.srcimg})` }} >
                                    <div className="category-content hidden">
                                        <h3>{el.name}</h3>
                                        <img src={el.srcimg} alt="img" />
                                        <div className="detailed">
                                            <p>  {t("shop18")} &nbsp; <FaBalanceScaleLeft /> &nbsp; : &nbsp; {el.gsm} </p>
                                            <p>  {t("shop19")}  &nbsp; <FaTextWidth />&nbsp; :&nbsp; {el.width} </p>
                                            <Link to={`${idx}`} state={{ categories }} style={{ textDecoration: 'none', color: 'inherit', margin: "auto" }} >
                                                <button className="button">{t("shop14")}</button>
                                            </Link>
                                        </div>

                                    </div>

                                    <div className="category-overlay"></div>
                                </div>

                            ))
                        }


                    </div>


                </div>
                <Footer />
            </div>
            {
                isVisible &&

                <FaArrowUp onClick={scrollToTop} className='arrowup' />
            }
            <Navbar ref={navRef} />
        </div >
    );
}

export default Shop;
